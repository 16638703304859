import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import actions from "../actions/catalog";
import { filterProducts } from "@Src_main/utils/productFilters";
export var name = "catalog";
var fromPairs = pairs => {
  var result = {};
  for (var [key, value] of pairs) {
    result[key] = value;
  }
  return result;
};
var initialState = {
  categories: {},
  sections: [],
  crossSellProducts: {},
  currentPage: 1,
  pageSize: 6,
  prevPageTotal: null,
  products: [],
  catalogHash: "",
  modifiers: {
    ingredients: ["Exclude Ingredients"],
    topping: ["AGGIUNTE", "MOD_TOPPING", "Aggiunte/Topping", "GELATO_AGGIUNTE", "AGG_MENU"],
    grattugiati: ["MOD_GRATTU", "Grattuggiati"],
    bread: ["MOD_PANE", "Aggiunte Pane"],
    condimento: ["MOD_CONDIMENTO", "GELATO1", "GELATO2", "GELATO3", "Scegli la tua pasta"],
    excluded_modifiers: ["DELIVERY_CONDIMENTO"],
    timings: ["Cottura", "WEB_COTTURA"]
  },
  filters: {
    ls_web_allergeni: {
      values: [],
      type: "filterByAllergen",
      visible_filter: false
    },
    ls_web_tracce_allergeni: {
      values: [],
      type: "filterByAllergen",
      visible_filter: false
    },
    ls_dish_stickers: {
      values: [],
      type: "filterByAttribute",
      correlated_filters: {
        vegan: ["vegetariano"]
      },
      disabled_filters: [],
      visible_filter: true
    },
    member_attribute_availability: {
      values: [],
      type: "filterByMemberAttribute",
      visible_filter: false
    }
  },
  filterPanel: {
    isOpen: false,
    hasBeenClicked: false,
    isTogglingOpen: false
  },
  filtered_products: [],
  filtered_sku: [],
  myoSelection: {
    products: {
      mainSku: []
    },
    options: [],
    productsFlat: [],
    originalProduct: null,
    price: 0
  },
  lastModalOpenSetter: () => {},
  mainMyoModalOpen: false
};
var reducerMap = {
  [actions.updateCategories]: (state, _ref) => {
    var {
      payload
    } = _ref;
    var {
      id
    } = payload;
    var currentCategory = state.categories[id] || {};

    // if category has already been fetched, do nothing
    if (currentCategory.children) {
      return state;
    }

    // sort children by `position`
    var children = [...payload.children].sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      } else if (a.position === b.position && a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });

    // use a Map to preserve sort order
    // since a plain object with numeric keys would lose it
    var childMap = new Map();

    // merge children and add them to the Map, keyed by `id`
    for (var child of children) {
      childMap.set(child.id, _objectSpread(_objectSpread(_objectSpread({}, child), state.categories[child.id] || {}), {}, {
        parentId: id
      }));
    }

    // merge in the fetched child last
    return _objectSpread(_objectSpread({}, state), {}, {
      categories: _objectSpread(_objectSpread(_objectSpread({}, state.categories), fromPairs(childMap)), {}, {
        [id]: _objectSpread(_objectSpread(_objectSpread({}, currentCategory), payload), {}, {
          children: [...childMap.keys()],
          children_count: childMap.size
        })
      })
    });
  },
  [actions.setCurrentPage.receive]: (state, _ref2) => {
    var {
      payload,
      error
    } = _ref2;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      currentPage: payload
    });
  },
  [actions.setPrevPageTotal.receive]: (state, _ref3) => {
    var {
      payload,
      error
    } = _ref3;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      prevPageTotal: payload
    });
  },
  [actions.setProductCatalog.receive]: (state, _ref4) => {
    var {
      payload,
      error
    } = _ref4;
    if (error) {
      return state;
    }
    var filtered_products = filterProducts(state.filters, payload, payload);
    return _objectSpread(_objectSpread({}, state), {}, {
      products: payload,
      filtered_products: filtered_products,
      filtered_sku: filtered_products.map(x => x.sku)
    });
  },
  [actions.setCatalogHash.receive]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      catalogHash: payload
    });
  },
  [actions.setFilters.receive]: (state, _ref6) => {
    var {
      payload,
      error
    } = _ref6;
    if (error) {
      return state;
    }
    var filtered_products = filterProducts(payload, state.products, state.products);
    return _objectSpread(_objectSpread({}, state), {}, {
      filters: payload,
      filtered_products: filtered_products,
      filtered_sku: filtered_products.map(x => x.sku)
    });
  },
  [actions.setFilterPanel.receive]: (state, _ref7) => {
    var {
      payload,
      error
    } = _ref7;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      filterPanel: payload
    });
  },
  [actions.setSectionCatalog.receive]: (state, _ref8) => {
    var {
      payload,
      error
    } = _ref8;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      sections: payload
    });
  },
  [actions.setCrossSellProductsMiniCart.receive]: (state, _ref9) => {
    var {
      payload,
      error
    } = _ref9;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      crossSellProducts: _objectSpread(_objectSpread({}, state.crossSellProducts), {}, {
        miniCart: payload
      })
    });
  },
  [actions.setCrossSellProductsModalFirst.receive]: (state, _ref10) => {
    var {
      payload,
      error
    } = _ref10;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      crossSellProducts: _objectSpread(_objectSpread({}, state.crossSellProducts), {}, {
        modalFirst: payload
      })
    });
  },
  [actions.setCrossSellProductsModalSecond.receive]: (state, _ref11) => {
    var {
      payload,
      error
    } = _ref11;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      crossSellProducts: _objectSpread(_objectSpread({}, state.crossSellProducts), {}, {
        modalSecond: payload
      })
    });
  },
  [actions.setCrossSellProductsBeforeCartModalFirst.receive]: (state, _ref12) => {
    var {
      payload,
      error
    } = _ref12;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      crossSellProducts: _objectSpread(_objectSpread({}, state.crossSellProducts), {}, {
        beforeCartModalFirst: payload
      })
    });
  },
  [actions.setCrossSellProductsBeforeCartModalSecond.receive]: (state, _ref13) => {
    var {
      payload,
      error
    } = _ref13;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      crossSellProducts: _objectSpread(_objectSpread({}, state.crossSellProducts), {}, {
        beforeCartModalSecond: payload
      })
    });
  },
  [actions.setMyoSelection.receive]: (state, _ref14) => {
    var {
      payload,
      error
    } = _ref14;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      myoSelection: payload
    });
  },
  [actions.setLastModalOpenSetter.receive]: (state, _ref15) => {
    var {
      payload,
      error
    } = _ref15;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      lastModalOpenSetter: payload
    });
  },
  [actions.setMainMyoModalOpen.receive]: (state, _ref16) => {
    var {
      payload,
      error
    } = _ref16;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      mainMyoModalOpen: payload
    });
  }
};
export default handleActions(reducerMap, initialState);